import React from "react";
import "./DogList.css";
function DogList({ data, onDelete }) {
  return (
    <div className="list">
      {data.map((item) => {
        return (
          <div className="item" key={item.id}>
            <span>
              {item.name} / {item.breed} / {item.age}
            </span>
            <button className="btn-delete" onClick={() => onDelete(item.id)}>
              x
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default DogList;
